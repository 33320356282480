import React from 'react'
import SEO from '../components/SEO'
import BostonHerald from '../images/press/logos/boston-herald-logo.png'
import BuiltIn from '../images/press/logos/built-in-logo.png'
import Time from '../images/press/logos/time-logo.png'
import Bloomberg from '../images/press/logos/bloomberg-logo.png'
import HrDrive from '../images/press/logos/hr-dive-logo.png'
import YahooFinance from '../images/press/logos/yahoo-finance-logo.png'
import ehsToday from '../images/press/logos/ehs-today-logo.png'
import hrTechCube from '../images/press/logos/hr-tech-cube-logo.png'
import hrMorning from '../images/press/logos/hr-morning-logo.png'
import livestrong from '../images/press/logos/livestrong-logo.png'
import foodNetwork from '../images/press/logos/food-network-logo.png'
import benefitsPro from '../images/press/logos/benefits-pro-logo.png'
import employeeBenefitNews from '../images/press/logos/employee-benefit-news-logo.png'
import allWork from '../images/press/logos/allwork-logo.png'
import bostinno from '../images/press/logos/bostinno-logo.png'
import nasdaq from '../images/press/logos/nasdaq-logo.png'
import laTimes from '../images/press/logos/la-times-logo.png'
import inBusiness from '../images/press/logos/in-business-logo.png'
import ohs from '../images/press/logos/ohs-logo.png'
import verywell from '../images/press/logos/verywell-logo.png'

export default function PressPage({ location }) {
  return (
    <>
      <SEO
        title="Goodpath news - press, articles, features"
        description="Press releases, articles, features, news, and more from Goodpath, a digital, integrative clinic for back pain, sleep, digestive health, and long COVID."
        location={location}
      />

      {/* Hero */}
      <div className="bg-gp-violet">
        <div className="flex items-center justify-center px-8 py-8 mx-auto text-center lg:py-16 lg:max-w-7xl">
          <div className="flex flex-col">
            <h1 className="text-4xl font-bold leading-tight lg:text-5xl text-gp-off-white">
              Goodpath Press
            </h1>
            <p className="mt-4 text-xl leading-snug md:text-2xl text-gp-off-white md:max-w-xl">
              Goodpath news, releases, features, and more. For media inquiries,
              contact{' '}
              <a
                href="mailto:braden@goodpath.com?subject=Media%20Query:%20"
                target="_blank"
                rel="noreferrer"
                className="text-white hover:text-gp-periwinkle"
              >
                Braden Sweet
              </a>
              .
            </p>
          </div>
        </div>
      </div>

      {/* Type 1 */}
      <div className="px-8 pb-32 mt-6">
        <div className="max-w-lg mx-auto divide-y-2 divide-gp-grey-50 lg:max-w-7xl">
          <div>
            <h2 className="text-3xl font-bold tracking-tight text-gp-grey-90 sm:text-4xl">
              Recent publications
            </h2>
          </div>
          <div className="grid pt-6 mt-6 gap-x-16 gap-y-8 lg:grid-cols-3 lg:gap-x-12 lg:gap-y-12">
            {posts.map((post) => (
              <div key={post.title} className="p-4 bg-gp-periwinkle-5">
                <div className="flex flex-grow flex-shrink-0 lg:flex-grow-0">
                  <img src={post.logo} alt={post.alt} className="h-12" />
                </div>
                <a
                  href={post.href}
                  target="_blank"
                  rel="noreferrer"
                  className="block mt-4"
                >
                  <p className="text-2xl font-bold leading-tight text-gp-grey-90">
                    {post.title}
                  </p>
                  {/* <p className="mt-3 text-lg font-normal leading-normal text-gp-grey-70">
                    {post.description}
                  </p> */}
                  <p className="mt-2 text-sm text-gp-grey-70">{post.date}</p>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

const posts = [
  {
    logo: YahooFinance,
    alt: 'Yahoo Finance logo.',
    title:
      'Sun Life U.S. and Goodpath partner to improve treatment for musculoskeletal conditions and long COVID-19',
    href:
      'https://finance.yahoo.com/news/sun-life-u-goodpath-partner-202000973.html',
    description: '',
    date: 'March 16, 2023'
  },
  {
    logo: bostinno,
    alt: 'BostInno logo.',
    title: 'Cambridge company tackles long Covid with an app',
    href:
      'https://www.bizjournals.com/boston/inno/stories/news/2022/05/17/goodpath-long-covid-treatment.html',
    description:
      'Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat massa dictumst amet. Sapien tortor lacus arcu.',
    date: 'May 17, 2022'
  },
  {
    logo: employeeBenefitNews,
    alt: 'Employee Benefit News logo.',
    title: 'Integrative care may be the key to helping people with long COVID',
    href:
      'https://www.benefitnews.com/news/goodpath-and-long-covid-recovery-through-integrative-care',
    description:
      'Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat massa dictumst amet. Sapien tortor lacus arcu.',
    date: 'April 12, 2022'
  },
  {
    logo: verywell,
    alt: 'Verywell Health logo.',
    title: "Is There a Way to Know If You've Never Had COVID?",
    href: 'https://www.verywellhealth.com/covid-antibody-testing-5225172',
    description:
      'Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat massa dictumst amet. Sapien tortor lacus arcu.',
    date: 'April 8, 2022'
  },
  {
    logo: laTimes,
    alt: 'LA Times logo.',
    title:
      'Expertos revelan cómo saber si sufre de síntomas persistentes de COVID tras meses de contagio',
    href:
      'https://www.latimes.com/espanol/california/articulo/2022-04-05/expertos-revelan-como-saber-si-sufre-de-sintomas-persistentes-de-covid-tras-meses-de-contagio',
    description:
      'Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat massa dictumst amet. Sapien tortor lacus arcu.',
    date: 'April 5, 2022'
  },
  {
    logo: BostonHerald,
    alt: 'Boston Herald logo.',
    title:
      'Boston digital health company offers long COVID treatment alternative',
    href:
      'https://www.bostonherald.com/2022/04/03/boston-startup-offers-long-covid-treatment-alternative/',
    description:
      'Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat massa dictumst amet. Sapien tortor lacus arcu.',
    date: 'April 3, 2022'
  },
  {
    logo: BuiltIn,
    alt: 'Built In logo.',
    title: 'What Everyone Needs to Know About Healthcare Benefits',
    href: 'https://builtin.com/people-management/healthcare-benefits',
    description:
      'Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat massa dictumst amet. Sapien tortor lacus arcu.',
    date: 'March 29, 2022'
  },
  {
    logo: Time,
    alt: 'Time logo.',
    title:
      'Back-to-Office Pressure Is Creating a Crisis for Long COVID Patients',
    href: 'https://time.com/6160010/long-covid-patients-back-to-work/',
    description:
      'Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat massa dictumst amet. Sapien tortor lacus arcu.',
    date: 'March 28, 2022'
  },
  {
    logo: Bloomberg,
    alt: 'Bloomberg logo.',
    title:
      'Yamaha Motor adds Goodpath Wellness Benefit to Underscore its Persistence, Innovation, and Vision in Employee Management',
    href:
      'https://www.bloomberg.com/press-releases/2022-03-10/yamaha-motor-adds-goodpath-wellness-benefit-to-underscore-its-persistence-innovation-and-vision-in-employee-management',
    description:
      'Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat massa dictumst amet. Sapien tortor lacus arcu.',
    date: 'March 10, 2022'
  },
  {
    logo: HrDrive,
    alt: 'HR Drive logo.',
    title:
      'When preparing to reopen, experts suggest planning for long COVID-19',
    href:
      'https://www.hrdive.com/news/when-preparing-to-reopen-experts-suggest-planning-for-long-covid-19/620063/',
    description:
      'Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat massa dictumst amet. Sapien tortor lacus arcu.',
    date: 'March 9, 2022'
  },
  {
    logo: YahooFinance,
    alt: 'Yahoo Finance logo.',
    title:
      'Hims & Hers to Expand Curated Educational Programs and Offerings Through Exclusive Partnership With Goodpath',
    href:
      'https://finance.yahoo.com/news/hims-hers-expand-curated-educational-135700518.html',
    description:
      'Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat massa dictumst amet. Sapien tortor lacus arcu.',
    date: 'March 2, 2022'
  },
  {
    logo: ehsToday,
    alt: 'EHS Today logo.',
    title: 'Long Haul COVID Affecting Workforce',
    href:
      'https://www.ehstoday.com/ehs-outloud-blog/article/21233742/long-haul-covid-affecting-workforce?fbclid=IwAR0GjXis1NpNiSjnx_ZTj0MyWp4mZyKHDFI5mZPJAOge4eHuL8BPx1GxTWs',
    description:
      'Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat massa dictumst amet. Sapien tortor lacus arcu.',
    date: 'February 18, 2022'
  },
  {
    logo: hrTechCube,
    alt: 'HR Tech Cube logo.',
    title:
      'Hrtech Interview with CEO and Co-Founder, Goodpath - Bill Gianoukos',
    href:
      'https://hrtechcube.com/hrtech-interview-with-ceo-and-co-founder-goodpath-bill-gianoukos/',
    description:
      'Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat massa dictumst amet. Sapien tortor lacus arcu.',
    date: 'June 30, 2021'
  },
  {
    logo: inBusiness,
    alt: 'IN Business Magazine logo.',
    title: 'Integrative Healthcare - The Ideal Benefit for 2021',
    href:
      'https://inbusinessphx.com/healthcare-wellness/integrative-healthcare-the-ideal-benefit-for-2021#.YGSUM68zaHu',
    description:
      'Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat massa dictumst amet. Sapien tortor lacus arcu.',
    date: 'March 31, 2021'
  },
  {
    logo: ohs,
    alt: 'Occupational Health & Safety logo.',
    title: 'Should Employers Consider Benefits Tailored to the Whole Employee?',
    href:
      'https://ohsonline.com/Articles/2021/03/10/Should-Employers-Consider-Benefits-Tailored-to-the-Whole-Employee.aspx?m=1&Page=1',
    description:
      'Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat massa dictumst amet. Sapien tortor lacus arcu.',
    date: 'March 10, 2021'
  },
  {
    logo: hrMorning,
    alt: 'HR Morning logo.',
    title: 'The hottest trend in benefits for 2021',
    href: 'https://www.hrmorning.com/articles/integrative-care-benefits/',
    description:
      'Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat massa dictumst amet. Sapien tortor lacus arcu.',
    date: 'February 25, 2021'
  },
  {
    logo: livestrong,
    alt: 'Livestrong logo.',
    title: 'Coronasomnia: What It Is and How to Treat It',
    href:
      'https://www.livestrong.com/article/13732002-what-is-coronasomnia-treatment/',
    description:
      'Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat massa dictumst amet. Sapien tortor lacus arcu.',
    date: 'February 23, 2021'
  },
  {
    logo: foodNetwork,
    alt: 'Food Network logo.',
    title: 'The New Rules on Sleep',
    href:
      'https://www.foodnetwork.com/healthyeats/healthy-tips/new-rules-on-sleep-2021',
    description:
      'Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat massa dictumst amet. Sapien tortor lacus arcu.',
    date: 'February 5, 2021'
  },
  {
    logo: ehsToday,
    alt: 'EHS Today logo.',
    title:
      'Can Integrative Health Solutions Help Employees While Cutting Costs?',
    href:
      'https://www.ehstoday.com/health/article/21154120/can-integrative-health-solutions-help-employees-while-cutting-costs',
    description:
      'Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat massa dictumst amet. Sapien tortor lacus arcu.',
    date: 'February 5, 2022'
  },
  {
    logo: employeeBenefitNews,
    alt: 'Employee Benefit News logo.',
    title: 'Why your employees need integrative wellness benefits',
    href:
      'https://www.benefitnews.com/news/why-your-employees-need-integrative-wellness-benefits',
    description:
      'Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat massa dictumst amet. Sapien tortor lacus arcu.',
    date: 'January 25, 2021'
  },
  {
    logo: benefitsPro,
    alt: 'Benefits Pro logo.',
    title: 'Why 2021 is the year integrative care is part of benefits',
    href:
      'https://www.benefitspro.com/2021/01/13/why-2021-will-be-the-year-benefits-packages-leverage-integrative-care/',
    description:
      'Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat massa dictumst amet. Sapien tortor lacus arcu.',
    date: 'January 13, 2021'
  },
  {
    logo: nasdaq,
    alt: 'Nasdaq logo.',
    title: 'How Companies are Reimagining Benefits in 2021',
    href:
      'https://www.nasdaq.com/articles/how-companies-are-reimagining-health-benefits-for-2021-2021-01-04',
    description:
      'Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat massa dictumst amet. Sapien tortor lacus arcu.',
    date: 'January 4, 2021'
  },
  {
    logo: allWork,
    alt: 'AllWork logo.',
    title:
      'Is Self-Care The Future Of Workplace Wellness? A Q&A With Goodpath Co-Founder, Bill Gianoukos',
    href:
      'https://allwork.space/2020/12/is-self-care-the-future-of-workplace-wellness-a-qa-with-goodpath-co-founder-bill-gianoukos/',
    description:
      'Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat massa dictumst amet. Sapien tortor lacus arcu.',
    date: 'December 23, 2020'
  },
  {
    logo: bostinno,
    alt: 'BostInno logo.',
    title: '21 Start-Ups to Watch in 2021',
    href:
      'https://www.bizjournals.com/boston/inno/stories/startups-to-watch/2020/12/15/21-startups-to-watch-in-boston-in-2021-bostinno.html',
    description:
      'Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat massa dictumst amet. Sapien tortor lacus arcu.',
    date: 'December 15, 2020'
  }
]
